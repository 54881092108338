import { closeSession } from "../utils/StepService";
import { getSessionData, getSessionId, getShadowRoot } from "../utils/store";
import { request } from "../utils/axios";
import { getBaseUri, isMobile, setError } from "../utils";

class Error {
  render = async () => {
    const result = await this.getFinalStatus();
    let errorMessage = this.getErrorMessage(result);
    let modalBox = getShadowRoot().querySelector("#identity-modal__box");
    if (modalBox.querySelector("#errorContent")) return;
    const isMobileVerification = this.isMobileVerification();
    modalBox.insertAdjacentHTML(
      "beforeend",
      `<form class="form-content" id="errorContent" data-form-tab>
                                <div class="identity__text-center identity__text-error">
                                <svg class="svg" width="74px" height="74px" aria-hidden="true" role="img"><use href="#svg__error" xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg__error"></use></svg>
                                </div>
                                <h3 class="identity__text-center">Denied</h3>
                                <div class="identity__row">
                                <div class="identity__col-12">
                                  ${errorMessage}
                                </div>
                                <div class="identity__col-6">
                                  <div class="identity-field__data">
                                    <label>Account</label>
                                      ${result.accountName}
                                  </div>
                                </div>
                                <div class="identity__col-6">
                                  <!--<div class="identity-field__data">
                                    <label>RCN</label>
                                    000000000
                                  </div>-->
                                </div>
                                <div class="identity__col-6">
                                    <div class="identity-field__data">
                                    <label>Name</label>
                                    ${result?.personalInfo?.firstName || ""} ${
        result?.personalInfo?.lastName || ""
      }
                                    </div>
                                </div>
                                <div class="identity__col-6">
                                    <div class="identity-field__data">
                                    <label>DOB</label>
                                    ${result?.personalInfo?.dob}
                                    </div>
                                </div>
                                ${
                                  !isMobileVerification
                                    ? `<div class="identity__col-12">
                                        <div class="identity-field">
                                          <button class="identity-btn identity-btn__success" type="submit">Accept</button>
                                        </div>  
                                      </div>`
                                    : ""
                                }
                                
                                </div>
                            </form>`
    );

    this.bindEvents();
  };

  getErrorMessage(result) {
    if(this.isMobileVerification())
      return `<p class="identity__text-center identity__mt0">Your verification failed. You may now close this page and continue from website.</p>`


    let message;
    message = result?.errorMessage
      ? `<p class="identity__text-center identity__mt0">Unfortunately, ${
          result?.personalInfo?.firstName || ""
        } ${
          result?.personalInfo?.lastName || ""
        }, your verification failed due to following reason.</p><ul><li>${
          result.errorMessage
        }</li></ul>`
      : `<p class="identity__text-center identity__mt0">Unfortunately, ${
          result?.personalInfo?.firstName || ""
        } ${
          result?.personalInfo?.lastName || ""
        } was denied access due to the ID used being invalid.</p>`;
    return message;
  }

  getFinalStatus = () => {
    return request
      .get(`/FinalStatus/${getSessionId()}`)
      .then(({ data }) => {
        let result = data.data;
        if (result?.personalInfo?.dob) {
          result.personalInfo.dob = result.personalInfo.dob.replace(
            /^(\d{4})[-\/](\d{1,2})[-\/](\d{1,2})$/,
            "$2-$3-$1"
          );
        }
        return result;
      })
      .catch(({ response }) => {
        setError(response);
      });
  };

  formatDate(inputDate) {
    if (!inputDate) return "";
    var date = new Date(inputDate);
    if (!isNaN(date.getTime())) {
      // Months use 0 index.
      return (
        date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear()
      );
    }
  }

  bindEvents() {
    const successForm = getShadowRoot().querySelector("#errorContent");

    successForm.addEventListener("submit", (event) => {
      event.preventDefault();
      closeSession();
    });
  }

  isMobileVerification() {
    let sessionData = getSessionData();
    return isMobile() && sessionData?.mobileVerificationType !== null && sessionData?.mobileVerificationType !== undefined;
  }
}

export default Error;
